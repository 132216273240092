import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";
import AOS from 'aos'

import 'aos/dist/aos.css'



import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';
//and then use it in main.js
Vue.use(VueAnalytics, {
    id: '309248684',
    router
});

Vue.use(VueMeta, {
   keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})


import "./assets/vendor/aos/aos.css"
import"./assets/vendor/bootstrap/css/bootstrap.min.css"

import"./assets/vendor/glightbox/css/glightbox.min.css"
import"./assets/vendor/remixicon/remixicon.css"
import"./assets/vendor/swiper/swiper-bundle.min.css"


import"./assets/vendor/bootstrap-icons/bootstrap-icons.css"


import "./assets/css/style.css"


import "./assets/css/customstyle.css"


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import VueQuillEditor from 'vue-quill-editor'
 
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

 
Vue.use(VueQuillEditor, /* { default global options } */)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Toast)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)


 


// router.beforeEach((to, from, next) => {
//   if(to.name == "Login"){
//     next();
//   }
//   else if(to.name == 'reset_password'){
//     next();
//   }
//   else if(to.name == 'forget_password'){
//     next();
//   }
//   else{
//     if (!localStorage.getItem("token")) {
//       next("/login");
//     }
  
//      else {
//       next();
//     }
//   }
  
// });


const main = new Vue({
  created () {
     
    AOS.init()
  },
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
export default main;