import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  singlePageData:null,
  TagNews:null,
  componentData: null,
  filePDF : [],
  filePDFSize : null,
  loading:false,
  viewReload:false,
  componentData:null,
  errorMessage:null,
  jobData:null,
  sigleKnowledge:null,
  projectInformation:null,
  images : [],
  images1 : [],
  images2 : [],
  images3 : [],
  images4 : [],
  images5 : [],
  images6 : [],
  images7 : [],
  images8 : [],
  images9 : [],
  images10 : [],
  images11 : [],
  images12 : [],
  images13 : [],
  images14 : [],
  images15 : [],

}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations
})