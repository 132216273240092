import axios from "axios";
import Vue from 'vue';
import {logout} from "./service"
import store from '../../store'
import router from "@/router/index";
import main from "../../main"

// const {logout} = require("./service");


const http = axios.create({
  // baseURL: process.env.VUE_APP_API_BASE_URL,

  baseURL: "https://firstconsultet.com/api/",

  // baseURL: "http://162.240.49.105:3000/api/"
  // baseURL:"http://192.168.100.11/api/"
  // baseURL:"http://192.168.1.9:3000/api/"

});
function ConverToErrorMessage(e) {
  store.commit("set",['loading',true]);
  if (e.response != null) {
    if (e.response.status == 400) {
      return [{ "err": "400 Bad Request" }];
    }
    else if (e.response.status == 401) {
      
      return [{ "err": "401 Unauthorized User" }];
    }
    else if (e.response.status == 402) {
      return [{ "err": "402 Payment Required" }];
    }
    else if (e.response.status == 403) {
      return [{ "err": "403 Forbidden" }];
    }
    else if (e.response.status == 404) {
      return [{ "err": "404 Not Found" }];
    }
    else if (e.response.status == 405) {
      return [{ "err": "405 Method Not Allowed" }];
    }
    else if (e.response.status == 406) {
      return [{ "err": "406 Not Acceptable" }];
    } else if (e.response.status == 429) {
      return [{ "err": "429 Too Many Requests" }];
    } else if (e.response.status == 422) {
      return [{ "err": "422 Unprocessable Entity Problem with your form" }];
    }

    else if (e.response.status == 408) {
      return [{ "err": "408 Request Timeout" }];
    } else if (e.response.status == 415) {
      return [{ "err": "415 Unsupported Media Type" }];
    } else if (e.response.status == 500) {
      return [{ "err": "500 Internal Server Error" }];
    } else if (e.response.status == 503) {
      return [{ "err": "503 Service Unavailable" }];
    } else if (e.response.status == 511) {
      return [{ "err": "511 Network Authentication Required" }];
    }
    else if (200 >= e.response.status && e.response.status <= 208) {
      return [e];
    }
    else {
      return [{ "err": e.message }];
    }
  }
  else {

    return [{ "err": "connection refused" }];
  }
}


http.interceptors.response.use(null, function (error) {
  store.commit("set",['loading',false]);
  if(error.response == undefined){
    let message = "connection refused";
    
    // and in the external js get the params like
    
    if(main.$route.path.startsWith('/home') || main.$route.path.startsWith('/login') ){
    
    Vue.$toast.error(
      message, {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    );
  }
  }
  if (error != null) {


  
  if (error.response.status == 401) {


    const token = localStorage.getItem("token");

  if (!token) {
    localStorage.clear();
    let message = ConverToErrorMessage(error);
    if(main.$route.path.startsWith('/home') || main.$route.path.startsWith('/login')){
    Vue.$toast.error(
      message[0].err, {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    );}
    router.replace("/login");
  } else {
    logout(token).then(
      (resp) => {
        localStorage.clear();
        let message = ConverToErrorMessage(error);
        Vue.$toast.error(
          message[0].err, {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        }
        );
        router.replace("/login");
      },
      (err) => {
        localStorage.clear();

        router.replace("/login");
      }
    );
  }


    // router.replace('/Login')
  }
  else if (error.response.status == 403) {

    router.push('/Forbidden')
  }else{
    let message = ConverToErrorMessage(error);

    Vue.$toast.error(
      message[0].err, {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    );
  }

}else{
  let message = "Success Message";
  Vue.$toast.success(
    message, {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
  }
  );
}
  return Promise.reject(error)
})


export default http;
