const cloudnaryUrl = "https://res.cloudinary.com/dhvxgn9xz/image/upload/";
const imgUrl = "https://api.cloudinary.com/v1_1/dhvxgn9xz/image/upload?upload_preset=sravrhin";
const fileUploadlocal = "containers/file/"
const resume =  "containers/resume/"
const perPage = 10;



// apicallback functions 
// const apiUrl = "http://196.189.91.209:3000/api/"
const apiUrl = "https://firstconsultet.com/api/"
// const apiUrl = "http://192.168.100.11/api/"
// const apiUrl = "http://192.168.1.9:3000/api/"
import http from "./http-client";


export function getUrl() {
  return imgUrl
}
export function getUrlForLocalUpload(upload) {
  if (upload) {
    return apiUrl + fileUploadlocal + 'upload'
  } else {
    return apiUrl + fileUploadlocal + 'download'
  }

}
export function getUrlForResume(upload) {
  if (upload) {
    return apiUrl + resume + 'upload'
  } else {
    return apiUrl + resume + 'download'
  }

}
export function getPerPage() {
  return perPage
}
export function imageToBanner(type, image) {
  var Image = image.split("/upload/");
  var imageurl;
  if (type == "banner") {
    imageurl = cloudnaryUrl + "/c_fill,g_auto,h_250,w_970/b_rgb:000000,e_gradient_fade,y_-0.10/c_scale,co_rgb:ffffff,fl_relative,l_text:montserrat_25_style_light_align_center:Shop Now,w_0.01,y_0.18/" + Image[1];

    return imageurl;
  }
  if (type = "lowQuality") {
    imageurl = cloudnaryUrl + "/q_10/" + Image[1];
    return imageurl;
  }
  return;
}




export function Login(data) {
  return (http.post(apiUrl + "users/login" + '?include=User', data))
}
export function logout(token) {
  return (http.post(apiUrl + '/users/logout' + '?' + 'access_token=' + token))
}
export function postFields(database, data, token) {
  return (http.post(apiUrl + '/' + database + '?' + 'access_token=' + token, data,{contentType: 'application/vnd.api+json'}))
}
export function getFields(database, token, where, include,order) {

  if (where && !include && token && !order) {
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"where":{"' + where.type + '":"' + where.value + '"}}'));
  } else if (where && include && token && !order) {
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"where":{"' + where.type + '":"' + where.value + '"},' + '"include":"' + include + '"}'));
  } else if (!where && include && token && !order) {
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"include":"' + include + '"}'));
  }
  else if (where && !include && !token && !order) {
    return (http.get(apiUrl + database + '?filter={"where":{"' + where.type + '":"' + where.value + '"}}'));
  }else if (where && include && !token && !order) {
    return (http.get(apiUrl + database + '?filter={"where":{"' + where.type + '":"' + where.value + '"},' + '"include":"' + include + '"}'));
  }else if (!where && include && !token && !order) {
    return (http.get(apiUrl + database + '?filter={"include":"' + include + '"}'));
  }else if(where && !include && !token && order){
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"where":{"' + where.type + '":"' + where.value + '"},' + '"order":"' + order.value + ' ' + order.type  + '"}'));
  }
  else if(!where && !include && token && order){
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={' + '"order":"' + order.value + ' ' + order.type  + '"}'));
  }
  else if(where && !include && token && order){
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"where":{"' + where.type + '":"' + where.value + '"},' + '"order":"' + order.value + ' ' + order.type  + '"}'));
  }

  else {
    return (http.get(apiUrl + database));
  }


}
export function removeRelation(database,id,submodule,fk,token){
  return (http.delete(apiUrl + database +'/'+id+'/'+ submodule +'/'+ fk + '?access_token=' + token));

}
export function removeFile(database,id,token){
  return (http.delete(apiUrl + database +'/'+id+'?access_token=' + token));

}
export function getFieldsRelationPivot(database,id,submodule, token, include) {
    return (http.get(apiUrl + database +'/'+id+'/'+ submodule + '?access_token=' + token + '&filter={"include":"' + include + '"}'));
}
export function getFieldsRelationOneToMany(database,id,submodule, token) {
  return (http.get(apiUrl + database +'/'+id+'/'+ submodule + '?access_token=' + token ));
}
export function getFieldsMultipleWhere(database, token, where, include) {
  let whereQue = '';
  if(where){
     for (let index = 0; index < where.length; index++) {
    const element = where[index];
    if(index != 0){
      whereQue = '"'+ whereQue + element.type + '":"' + element.value+'"' + ','
    }else{
      whereQue = '"'+ whereQue + element.type + '":"' + element.value+'"'
    }
    
    
  }
  }
 
  if (where && !include && token) {
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"where":{' +whereQue + '}}'));
  } else if (where && include && token) {
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"where":{' + whereQue + '},' + '"include":"' + include + '"}'));
  } else if (!where && include && token) {
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"include":"' + include + '"}'));
  }

  else {
    return (http.get(apiUrl + database));
  }


}

export function getFieldById(database,id,token,include){
  if(token){
      return (http.get(apiUrl + database + '/' + id + '?access_token=' + token  ));
  }else if(!token && include){
    return (http.get(apiUrl + database + '/' + id +  '?filter={"include":"'+include+'"}' ));
  }else if(!token && !include){
    return (http.get(apiUrl + database + '/' + id  ));
  }
}

export function getComponentData(database, token, id, include) {
  if(id && include.last){
      return (http.get(apiUrl + database + '/' + id + '?access_token=' + token + '&filter={"include":{"' + include.main + '":{"' + include.sub + '":"' + include.last + '"}}}'));
  }
  else if(!id && !include.last){
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"include":{"' + include.main + '":"' + include.sub + '"}}'));
  }
  else if(!id && !include.last && !include.sub){
    return (http.get(apiUrl + database + '?access_token=' + token + '&filter={"include":"' + include.main + '"}'));
  }
}
export function getSectorsKnowledge(database,subData,token,id,include){
  return (http.get(apiUrl + database + '/' + id + '/' + subData + '?access_token=' + token + '&filter={"include":["' + include[0] + '","' + include[1] + '"]}'));
}

export function getSubModel(database,subData,token,id,order){
  console.log("order order")
  if(order){
    return (http.get(apiUrl + database + '/' + id + '/' + subData + '?access_token=' + token + '&filter={"order":"' + order.type + ' ' + order.value +`"}` ));  
  }
  return (http.get(apiUrl + database + '/' + id + '/' + subData + '?access_token=' + token ));
}
export function getFieldsPage(database, page, token,include) {
  let skip = (parseInt(page) - 1) * perPage;
  if(include){
    return (http.get(apiUrl + '/' + database + '?' + 'access_token=' + token + `&filter={` + `"limit":` + perPage + `,"skip":` + skip + `,"order": "date DESC","include" :"`+include+`"}`))
  }
  else{
    return (http.get(apiUrl + '/' + database + '?' + 'access_token=' + token + `&filter={` + `"limit":` + perPage + `,"skip":` + skip + `,"order": "date DESC"`+`}`))
  }
}
export function getDataFilterByDate(database,toDate,fromDate,token){

  return (http.get(apiUrl + '/' + database + '?'  + 'access_token=' + token  + '&filter={"where":{"and":[ { "date": { "lte": "'+toDate+'" } }, { "date": { "gte":"'+ fromDate +'"} }]}}' ,))
}

export function getFieldsByOneProperty(database, type, value, token) {
  return (http.get(apiUrl + '/' + database + '?' + 'access_token=' + token + '&filter={"where":{"' + type + '":"' + value + '"}}',))
}
export function getDatas(database, token, order, limit) {
  if (order && limit && token) {
    return (http.get(apiUrl + '/' + database + '?' + 'access_token=' + token + '&filter={"order":"' + order.type + ' ' + order.value + '","limit":' + limit + '}',))
  }else if(order && limit && !token){
    return (http.get(apiUrl + '/' + database + '?'  + 'filter={"order":"' + order.type + ' ' + order.value + '","limit":' + limit + '}',))

  }
}


export function pathFieldWithOutId(database, data, token) {
  return (http.patch(apiUrl + '/' + database + '?' + 'access_token=' + token, data,{contentType: 'application/vnd.api+json'}))
}


export async function makeRelationToIntervention(value, id) {
  let allresponce = [];
  for (let index = 0; index < this.value.length; index++) {
    const element = this.value[index];
    let interventionId = this.allInterventions.filter(
      (inetervention) => inetervention.header == element
    )[0];
    let resp = await pathField(
      "Inerventions",
      interventionId,
      { componentId: id },
      token
    );
    allresponce.push(resp);
  }

  return await allresponce;
}


export function pathField(database, id, data, token) {
  return (http.patch(apiUrl + '/' + database + '/' + id + '?' + 'access_token=' + token, data,{contentType: 'application/vnd.api+json'}))
}
export function postData(database, data, token) {
  return ((http.post(apiUrl + '/' + database + '?' + 'access_token=' + token + '&Data=' + JSON.stringify(data))))
}
export function postDataHeader(url, data, token) {
 
  if (token) {
    return (http.post(apiUrl + url + '?access_token=' + token + '&Data=' + JSON.stringify(data),null,{contentType: 'application/vnd.api+json'}))
  }
  return (http.post(apiUrl + url + '?Data=' + JSON.stringify(data)))
}
