import Vue from 'vue'
import Router from 'vue-router'




// pages for website admin 

const addNews = () => import("@/web-admin/news/AddNews");
const ViewTag = () => import("@/web-admin/Tag/ViewTag");
const viewNews = () => import("@/web-admin/news/ViewNews");
const News = () => import("@/web-admin/news/News");
const EditNews = () => import("@/web-admin/news/EditNews")
const singleNews = ()=> import("@/web-admin/news/SingleNews");
const addEvent = () => import("@/web-admin/event/AddEvent");
const Event = () => import("@/web-admin/event/Event");
const viewEvents = () => import("@/web-admin/event/ViewEvents");
const singleEvent = ()=> import("@/web-admin/event/SingleEvent");
const comment = ()=> import("@/web-admin/contactUs/ContactUs");
const CreateAreaOfExperties = ()=> import("@/web-admin/AreaOfExperties/CreateNew");
const ViewAreasOfExpertise = () => import("@/web-admin/AreaOfExperties/ViewAreaOfExperties");
const EditAreaOfExpertise = () => import("@/web-admin/AreaOfExperties/EditAreaOfExperties");
const AreasOfExpertise = () => import("@/web-admin/AreaOfExperties/AreaOfExperties")
const Subscription = ()=> import("@/web-admin/Subscription/viewSubscription");
const AddPeople = ()=> import("@/web-admin/People/addPeople");
const EditPeople = () =>import("@/web-admin/People/EditPeople");
const ViewPeople = ()=> import("@/web-admin/People/ViewPeople");
const People = ()=> import("@/web-admin/People/People");
const AddPartners = ()=> import("@/web-admin/Partners/addPartners");
const EditPartners = () =>import("@/web-admin/Partners/EditPartners");
const ViewPartners = ()=> import("@/web-admin/Partners/ViewPartners");
const Partners = ()=> import("@/web-admin/Partners/Partners");



const AddKnowledge = ()=> import("@/web-admin/Knowledge/createKnowledge");
const EditKnowledge = () =>import("@/web-admin/Knowledge/EditKnowledge");
const ViewKnowledge = ()=> import("@/web-admin/Knowledge/ViewKnowledge");
const Knowledges = ()=> import("@/web-admin/Knowledge/Knowledge");

const AddIntervention = ()=> import("@/web-admin/intervention/createIntervention");
const EditIntervention = () =>import("@/web-admin/intervention/EditIntervention");
const ViewIntervention = ()=> import("@/web-admin/intervention/ViewIntervention");
const Intervention = ()=> import("@/web-admin/intervention/Intervention");


const AddSector =() => import("@/web-admin/Sector/addSector");
const EditSector = () =>import("@/web-admin/Sector/EditSector");
const ViewSector = ()=> import("@/web-admin/Sector/ViewSector");
const Sector = ()=> import("@/web-admin/Sector/Sector");


const AddComponent =() => import("@/web-admin/Component/AddComponent");
const EditComponent = () =>import("@/web-admin/Component/EditComponent");
const ViewComponent = ()=> import("@/web-admin/Component/ViewComponent");
const Component = ()=> import("@/web-admin/Component/Component");


const AddProject =() => import("@/web-admin/Project/createProject");
const EditProject = () =>import("@/web-admin/Project/EditProject");
const ViewProject = ()=> import("@/web-admin/Project/ViewProject");
const Projects = ()=> import("@/web-admin/Project/Project");



const AddCareers =() => import("@/web-admin/Careers/CareersNew");
const Companies =() => import("@/web-admin/Careers/Companies");
const JobSeekers =() => import("@/web-admin/Careers/Employees");
const Jobs =() => import("@/web-admin/Careers/Jobs");
const EditCareers = () =>import("@/web-admin/Careers/EditCareers");
const ViewCareers = ()=> import("@/web-admin/Careers/ViewCareers");
const Careerss = ()=> import("@/web-admin/Careers/Careers");


const InterventionAdd = () => import("@/web-admin/intervention/createIntervention");
const SectorAdd = () => import("@/web-admin/Sector/addSector")
const ComponentAdd = () => import("@/web-admin/Component/AddComponent")
const ProjectAdd = () => import("@/web-admin/Project/createProject")
const ChangeAcomplishment = () => import("@/web-admin/Project/cangeAcomplishment")





// landing page routes
const LandingContainer = ()=> import("@/landingPage/container/Container");
const MainPage = ()=> import("@/landingPage/HomePage")
const scrolltest = ()=> import("@/landingPage/scrolltest")
const AboutUs = ()=> import("@/landingPage/AboutUs")
const Knowledge = ()=> import("@/landingPage/Knowledge")
const OurWorks = ()=> import("@/landingPage/OurWorks")
const Blog = ()=> import("@/landingPage/Blog")
const SingleBlog = ()=> import("@/landingPage/SingleBlog")
const LandingDashBoard = () => import("@/landingPage/DashBoard")
const Career = () => import("@/landingPage/Career")
const ContactUs =  () => import("@/landingPage/ContactUs")
const Project = () => import("@/landingPage/Project/Project")
const ProjectContent = () => import("@/landingPage/Project/Content")
const Components = () => import("@/landingPage/Project/Components")
const SingleKnowledge = () => import("@/landingPage/SingleKnowledge")
const CareerDetail = () => import("@/landingPage/CareerDetail")
const Test = () => import ("@/landingPage/test")












// Containers
const TheContainer = () => import('@/containers/TheContainer')


// Views
const Dashboard = () => import('@/views/Dashboard')


const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

export default new Router({
  // https://router.vuejs.org/api/#mode
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
   {
     path : "*",
     component:LandingContainer,
      redirect:'/home',
   },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path : '/test',
      component : Test
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path:'/d',

      component:scrolltest
    },    

    {
      path: '/',
      name: 'main',
      component:LandingContainer,
      redirect:'/home_page',
      children:[
        {
          path:'home_page',
          name:'home_page',
          component:MainPage
        },
        {
          path: '/home',
          redirect: 'home/dashboard',
          name: 'Home',
          component: TheContainer,
          beforeEnter: (to, from, next) => {
            if (!localStorage.getItem("token")) {
              next("/login");
            }
            else if (!localStorage.getItem("v_$@21jh0#3")) {
              next("/login");
            }
            else if (localStorage.getItem("v_$@21jh0#3") != 'd9c64cc9ef89ffd257fc7678d3088077') {
              next("/login");
            }else{
              next();
            }
    
          },
          children: [
           
            {
              path:'subscription',
              name:'subscription',
              component: Subscription
            },
            
          
            {
              path: 'add_component',
              name: 'add_component', 
              component: ComponentAdd
            },
    
            {
              path:'add_sector',
              name:'add_sector',
              component: SectorAdd
            },
            {
              path:'view_tag',
              name:'view Tag',
              component: ViewTag
            },
          
            {
              path:"areas_of_expertise",
              name:"Areas of expertise",
              component:AreasOfExpertise,
              redirect:"areas_of_expertise/view",
              children:[
                {
                  path:"view",
                  component:ViewAreasOfExpertise,
                  name: "View"
                },
                {
                  path:"edit_area",
                  
                  name:"Edit",
                  component:EditAreaOfExpertise,
                },
                {
                  path:'create_area',
                  name:'Create',
                  component: CreateAreaOfExperties
                },
              ]
            },
    
    
    
    
              
            {
              path:'intervention',
              name:'Intervention',
              redirect: 'intervention/view_intervention',
              component: Intervention,
              
              children: [
                {
                  path:'view_intervention',
                  name: 'View',
                  component: ViewIntervention,
                },
                {
                  path:'add_intervention',
                  name: 'Add Intervention',
                  component: AddIntervention
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditIntervention
                }
              ]
            },
            {
              path:'component',
              name:'Component',
              redirect: 'component/view_component',
              component: Component,
              
              children: [
                {
                  path:'view_component',
                  name: 'View',
                  component: ViewComponent,
                },
                {
                  path:'add_component',
                  name: 'Add Component',
                  component: AddComponent
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditComponent
                }
              ]
            },
            {
              path:'project',
              name:'Project',
              redirect: 'project/view_project',
              component: Projects,
              
              children: [
                {
                  path:'view_project',
                  name: 'View',
                  component: ViewProject,
                },
                {
                  path:'add_project',
                  name: 'Add Project',
                  component: AddProject
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditProject
                },
                
          {
                  path: 'change_acomplishment',
                  name: 'change_acomplishment',
                  component: ChangeAcomplishment,
                },
              ]
            },
            {
              path:'careers',
              name:'Careers',
              redirect: 'careers/view_careers',
              component: Careerss,
              
              children: [
                {
                  path:'view_careers',
                  name: 'View',
                  component: ViewCareers,
                },
                {
                  path:'add_careers',
                  name: 'Add Careers',
                  component: AddCareers
                },
                 {
                  path:'comapanies',
                  name: 'Companies',
                  component: Companies
                },
                {
                  path:'job_seekers/:id',
                  name: 'Job Seekers',
                  component: JobSeekers
                },
                {
                  path:'jobs',
                  name: 'Jobs',
                  component: Jobs
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditCareers
                },
                
          {
                  path: 'change_acomplishment',
                  name: 'change_acomplishment',
                  component: ChangeAcomplishment,
                },
              ]
            },
            {
              path:'sector',
              name:'Sector',
              redirect: 'sector/view_sector',
              component: Sector,
              
              children: [
                {
                  path:'view_sector',
                  name: 'View',
                  component: ViewSector,
                },
                {
                  path:'add_sector',
                  name: 'Add Sector',
                  component: AddSector
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditSector
                }
              ]
            },
    
            {
              path:'knowledge',
              name:'Knowledge',
              redirect: 'knowledge/view_knowledge',
              component: Knowledges,
              
              children: [
                {
                  path:'view_knowledge',
                  name: 'View',
                  component: ViewKnowledge,
                },
                {
                  path:'add_knowledge',
                  name: 'Add Knowledge',
                  component: AddKnowledge
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditKnowledge
                }
              ]
            },
    
            {
              path:'partners',
              name:'Partners',
              redirect: 'partners/view_partners',
              component: Partners,
              
              children: [
                {
                  path:'view_partners',
                  name: 'View',
                  component: ViewPartners,
                },
                {
                  path:'add_partners',
                  name: 'Add Partners',
                  component: AddPartners
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditPartners
                }
              ]
            },
            {
              path:'people',
              name:'People',
              redirect: 'people/view_people',
              component: People,
              
              children: [
                {
                  path:'view_people',
                  name: 'View',
                  component: ViewPeople,
                },
                {
                  path:'add_people',
                  name: 'Add People',
                  component: AddPeople
                },
                {
                  path: 'edit',
                  name: 'Edit',
                  component: EditPeople
                }
              ]
            },
            {
              path:"news",
              redirect:'news/view_news',
              component: News,
              name:"News",
              children:[
                {
                  path: "view_news",
                  component: viewNews,
                  name: "View News",
                },
                {
                  path:"edit_news",
                  component : EditNews,
                  name:"Edit News"
                },
                {
                  path:"single_news/:id",
                  component: singleNews,
                  name:"single_news"
                },
                {
                  path:"add_news",
                  component:addNews,
                  name:"Add News"
                },
                
              ]
            },
            {
              path:"event",
              component:Event,
              redirect:"event/view_events",
              name:"event",
              children:[
                {
                  path: "view_events",
                  component: viewEvents,
                  name: "view_events",
                },
                {
                  path:"single_event",
                  component: singleEvent,
                  name:"single_event"
                },
                {
                  path:"add_event",
                  component:addEvent,
                  name:"add_event"
                },
              ]
            },
            {
              path:'contactus',
              name:'contactus',
              component:comment
            },
    
            
    
            {
              path: 'dashboard',
              name: 'Dashboard',
              component: Dashboard
            },
            {
              path: 'theme',
              redirect: '/theme/colors',
              name: 'Theme',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'colors',
                  name: 'Colors',
                  component: Colors
                },
                {
                  path: 'typography',
                  name: 'Typography',
                  component: Typography
                }
              ]
            },
            {
              path: 'charts',
              name: 'Charts',
              component: Charts
            },
            {
              path: 'widgets',
              name: 'Widgets',
              component: Widgets
            },
            {
              path: 'users',
              meta: {
                label: 'Users'
              },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Users',
                  component: Users
                },
                {
                  path: ':id',
                  meta: {
                    label: 'User Details'
                  },
                  name: 'User',
                  component: User
                }
              ]
            },
            {
              path: 'base',
              redirect: '/base/cards',
              name: 'Base',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'cards',
                  name: 'Cards',
                  component: Cards
                },
                {
                  path: 'forms',
                  name: 'Forms',
                  component: Forms
                },
                {
                  path: 'switches',
                  name: 'Switches',
                  component: Switches
                },
                {
                  path: 'tables',
                  name: 'Tables',
                  component: Tables
                },
                {
                  path: 'tabs',
                  name: 'Tabs',
                  component: Tabs
                },
                {
                  path: 'breadcrumbs',
                  name: 'Breadcrumbs',
                  component: Breadcrumbs
                },
                {
                  path: 'carousels',
                  name: 'Carousels',
                  component: Carousels
                },
                {
                  path: 'collapses',
                  name: 'Collapses',
                  component: Collapses
                },
                {
                  path: 'jumbotrons',
                  name: 'Jumbotrons',
                  component: Jumbotrons
                },
                {
                  path: 'list-groups',
                  name: 'List Groups',
                  component: ListGroups
                },
                {
                  path: 'navs',
                  name: 'Navs',
                  component: Navs
                },
                {
                  path: 'navbars',
                  name: 'Navbars',
                  component: Navbars
                },
                {
                  path: 'paginations',
                  name: 'Paginations',
                  component: Paginations
                },
                {
                  path: 'popovers',
                  name: 'Popovers',
                  component: Popovers
                },
                {
                  path: 'progress-bars',
                  name: 'Progress Bars',
                  component: ProgressBars
                },
                {
                  path: 'tooltips',
                  name: 'Tooltips',
                  component: Tooltips
                }
              ]
            },
            {
              path: 'buttons',
              redirect: '/buttons/standard-buttons',
              name: 'Buttons',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'standard-buttons',
                  name: 'Standard Buttons',
                  component: StandardButtons
                },
                {
                  path: 'button-groups',
                  name: 'Button Groups',
                  component: ButtonGroups
                },
                {
                  path: 'dropdowns',
                  name: 'Dropdowns',
                  component: Dropdowns
                },
                {
                  path: 'brand-buttons',
                  name: 'Brand Buttons',
                  component: BrandButtons
                }
              ]
            },
            {
              path: 'icons',
              redirect: '/icons/coreui-icons',
              name: 'CoreUI Icons',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'coreui-icons',
                  name: 'Icons library',
                  component: CoreUIIcons
                },
                {
                  path: 'brands',
                  name: 'Brands',
                  component: Brands
                },
                {
                  path: 'flags',
                  name: 'Flags',
                  component: Flags
                }
              ]
            },
            {
              path: 'notifications',
              redirect: '/notifications/alerts',
              name: 'Notifications',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'alerts',
                  name: 'Alerts',
                  component: Alerts
                },
                {
                  path: 'badges',
                  name: 'Badges',
                  component: Badges
                },
                {
                  path: 'modals',
                  name: 'Modals',
                  component: Modals
                }
              ]
            }
          ]
        },
        {
          path:'about_us',
          component:AboutUs,
          name:'about_us'
        },
        {
          path:'knowledge',
          component:Knowledge,
          name:'knowledge',
        },
        {
          path:'our_work',
          component:OurWorks,
          name:'our_work'
        },
        {
          path:'blog',
          component:Blog,
          name:'blog'
        },
        {
          path:'blog_description/:id',
          component:SingleBlog,
          name:'blog_description'
        },
        {
          path: 'dashboard',
          component : LandingDashBoard,
          name: 'dashboard'
        },
        {
          path: 'knowledge_materials/:id',
          component : SingleKnowledge,
          name: 'knowledge_materials'
        },
        {
          path: 'career',
          component : Career,
          name : 'career'
        },
        {
          path: 'contact_us',
          component : ContactUs,
          name : 'Contact Us'
        },
        {
          path: 'career-detail/:id',
          component :CareerDetail,
          name: 'career-detail'
        },
        {
          path: 'main/:id',
          component : ProjectContent,
          name: 'main',
          
          
        },
        {
          path: 'components/:id',
          component : Components,
          name : 'components'
        },
        {
          path: 'project',
          component : Project, 
          name : 'project',
          redirect:'project/main',
          children:[
            {
              path: 'main/:id',
              component : ProjectContent,
              name: 'main',
              
              
            },
            {
              path: 'components/:id',
              component : Components,
              name : 'components'
            }
          ]
        },
       
      ]
    },

    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

