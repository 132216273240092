<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue'
import { removeFile } from './assets/js/service'

export default {
  name: 'App',
}
Vue.mixin({
  methods: {
    globalDelete: function (database, id) {
      this.$store.commit("set", ["loading", true]);
      let token = localStorage.getItem('token');
      removeFile(database, id, token).then(resp => {
        this.$store.commit("set", ["loading", false]);
        this.$store.commit("set", ["viewReload", true]);
        Vue.$toast.success("Successful", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

      })
    },
  },
})
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
@import 'assets/vendor/aos/aos.css';
@import 'assets/vendor/bootstrap/css/bootstrap.min.css';
@import 'assets/vendor/bootstrap/css/bootstrap.min.css';
@import 'assets/vendor/glightbox/css/glightbox.min.css';
@import 'assets/vendor/remixicon/remixicon.css';

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Nunito:300,300i,400,400i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
);
@import 'assets/vendor/swiper/swiper-bundle.min.css';
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.2/dist/css/bootstrap.min.css');


@import 'assets/vendor/bootstrap-icons/bootstrap-icons.css';

body {
  font-size: 0.9rem !important;
}

table {
  font-weight: normal;
}


.error-message {
  color: red;
}

section {
  padding-bottom: 0 !important;
}

.daynamic-height {
  height: 45px;
}

.main-header {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 38px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}

.filter-card {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: 100%;
  overflow: hidden;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.hideContent {
  overflow: hidden;
  line-height: 1em;
  height: 2em;
}

.card-title {
  font-size: small !important;
}

.news-card img {
  height: 150px !important;
}

.horizontal-right {
  display: flex !important;
  justify-content: end !important;
}

.ql-video {
  visibility: hidden !important;
}

.ql-script {
  visibility: hidden !important;
}

.ql-direction {
  visibility: hidden !important;
}

.sucker {
  visibility: hidden;
}

.page-item.active .page-link {
  color: white !important;
  background-color: #013289 !important;
  border-color: #013289 !important;
}

.page-item .page-link {

  color: #013289 !important;
}

.sub-header {
  font-size: 30px;
  font-family: 'Poppins';
  color: #012970;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.headers {
  margin: -20px 0 0 0;
  padding: 0;
  font-size: 30px;
  font-family: 'Jost', sans-serif;
  ;
  line-height: 70px;
  font-weight: 700;
  color: #012970;
}

p img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0px !important;
  margin-top: 20px !important;

}

dd {
  margin: 10px !important;
}

.b-form-btn-label-control.form-control>.dropdown-menu {
  padding: 0.5rem;
  z-index: 10000 !important;
}

@media only screen and (max-width: 991px) and (min-width: 769px){
 /* CSS that should be displayed if width is equal to or less than 991px and larger 
  than 768px goes here */
}
</style>
